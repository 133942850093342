// extracted by mini-css-extract-plugin
export var boxContainer = "SucessStories-module--boxContainer--yyo-J";
export var caraIndicator = "SucessStories-module--caraIndicator--oO9rx";
export var caraIndicatorTop = "SucessStories-module--caraIndicatorTop--N6Gxa";
export var collapseLink = "SucessStories-module--collapseLink--diisr";
export var cusText1 = "SucessStories-module--cusText1--QMTov";
export var cusText2 = "SucessStories-module--cusText2--IR7Qc";
export var cusText3 = "SucessStories-module--cusText3--DFQUG";
export var custText33 = "SucessStories-module--custText33--Fous+";
export var darkHr = "SucessStories-module--darkHr--sGkaZ";
export var dateText1 = "SucessStories-module--dateText1--+ccHk";
export var docGraphImg = "SucessStories-module--docGraphImg--djXIL";
export var docImg1 = "SucessStories-module--docImg1--hUHTe";
export var drImg = "SucessStories-module--drImg---fBun";
export var faceText = "SucessStories-module--faceText--0mMZB";
export var greenPillBox = "SucessStories-module--greenPillBox--qIfVJ";
export var marginCo = "SucessStories-module--marginCo--sKe8E";
export var nextScreenArrow = "SucessStories-module--nextScreenArrow--GyjSb";
export var nosText = "SucessStories-module--nosText--ab3FP";
export var previousScreenArrow = "SucessStories-module--previousScreenArrow--RN7dH";
export var progText1 = "SucessStories-module--progText1--44PUr";
export var progText2 = "SucessStories-module--progText2---cfI7";
export var resContent2 = "SucessStories-module--resContent2--6lLHn";
export var resTitle1 = "SucessStories-module--resTitle1--tANOj";
export var resTitle11 = "SucessStories-module--resTitle11--pmell";
export var secondaryTxt = "SucessStories-module--secondaryTxt--V2KPg";
export var sucessStoriesV1_button_all = "SucessStories-module--sucessStoriesV1_button_all--1Mcp0";
export var sucessStoriesV1_container = "SucessStories-module--sucessStoriesV1_container--GuHoo";
export var sucessStoriesV1_heading = "SucessStories-module--sucessStoriesV1_heading--K216f";