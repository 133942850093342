import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Carousel } from "react-responsive-carousel";

// components
import DoctorStories from "./DoctorStories";

const stories = [
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user1.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Srivathsan Rangarajan reversed diabetes in 6 months",
    location: "37 years old, Bangaluru",
    description:
      "I have been looking for an alternate treatment for Diabetes and have been under medication for almost 8.5 years. Twin program gave me the confidence that i can continue without medication and control the blood sugar levels purely with diet and lifestyle change. In fact when i ...",
    readMoreLink: "/testimonial-details/?id=3",
    months: "6",
    a1cStart: "6.7",
    a1cEnd: "6.1",
    medicineStart: "2",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user1-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user2.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Dinakar Shetty reversed diabetes in 8 months",
    location: "64 years old, Bangaluru",
    description:
      "Twin program promises Health & Happiness. This I have experienced by enrolling into d Twin program in last May 2020. I was devastated with the diabetic reading of 726 though I was new to this disease. Immediately my Doctor put me on insulin. I was somehow not comfortable ...",
    readMoreLink: "/testimonial-details/?id=1",
    months: "8",
    a1cStart: "12.7",
    a1cEnd: "5.7",
    medicineStart: "4",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user2-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user3.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Nandani Singh reversed diabetes in 7 months",
    location: "61 years old, Delhi",
    description:
      "It was a life altering experience.I dropped 15kgs of excess weight and HB a1c from 7.3 to 6.1 in 3 months!! This is unbelievable and had I not gone through this program, I would have thought it impossible to repair and heal myself to good health. The structure and very ...",
    readMoreLink: "/testimonial-details/?id=2",
    months: "7",
    a1cStart: "7.3",
    a1cEnd: "6.1",
    medicineStart: "1",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user3-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
];

const CarouselStories = props => {
  const cId = props.carouselId || "successStoryCarousel";
  let showIndicators = true;
  if (props.showIndicators !== undefined) {
    showIndicators = props.showIndicators;
  }

  return (
    <>
      <Carousel
        id={cId}
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        infiniteLoop={true}
        interval={5000}
        // autoPlay={true}
        showIndicators={showIndicators}
        className={`homeCarousel`}
      >
        <DoctorStories {...stories[0]} />
        <DoctorStories {...stories[1]} />
        <DoctorStories {...stories[2]} />
      </Carousel>
    </>
  );
};

export default CarouselStories;
