import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

//styles
import * as styles from "./SucessStories.module.css";

const DoctorStories = props => {
  return (
    <div className="row justify-content-center pb-2">
      <div className="col-12 col-md-5 col-lg-5">
        <div className="row">
          <div className="col-4 d-flex justify-content-end justify-content-md-start justify-content-lg-start  order-md-first order-lg-frist order-last col-lg-12 col-lg-12 pb-5">
            <div className={styles.docImg1}>{props.userImage}</div>
          </div>

          <div className="col-8 order-first order-md-last order-lg-last col-lg-8 col-lg-8 pt-2 pt-md-0 pt-lg-0">
            <div className="col-12 text-start">
              <span className={styles.cusText1}>{props.heading}</span>
            </div>
            <div className="col-12 col-lg-12 col-lg-12 pb-2">
              <div className="text-start">
                <p className={styles.cusText2}>{props.location}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-none d-md-flex d-lg-flex col-lg-11 col-lg-11">
            <div className="text-start">
              <p className={`${styles.cusText3}`}>{props.description}</p>
              <p>
                <Link to={props.readMoreLink} className={styles.collapseLink}>
                  Read More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-5">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ maxWidth: "360px" }}
            >
              <p className={styles.custText33}>Start of the program</p>

              <p className={styles.custText33}>within {props.months} months</p>
            </div>            
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row align-items-center">
              <div className="d-flex">
                <div className="pe-3 mt-2">
                  <span className="smallIcon icon-drop" />
                </div>
                <div className="d-flex mt-2">
                  <p className={styles.progText1}>A1C: </p>
                  <p className={`${styles.progText2} ps-1`}>{props.a1cStart}</p>
                </div>
                <div className="ps-2 pt-1 pb-2 mt-2 pe-2">
                  <StaticImage
                    src="../../../images/home/bigArrow.svg"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div
                  className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                >
                  <span>{props.a1cEnd}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row align-items-center">
              <div className="d-flex" style={{ maxWidth: "384px" }}>
                <div className="pe-3 mt-2">
                  <span className="smallIcon icon-tablet" />
                </div>
                <div className="d-flex mt-2">
                  <p className={styles.progText1}>Medicines: </p>
                  <p className={`${styles.progText2} ps-1`}>
                    {props.medicineStart}
                  </p>
                </div>
                <div className="ps-2 pt-1 mt-2 pe-2">
                  <StaticImage
                    src="../../../images/home/bigArrow.svg"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div
                  className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                >
                  <span>{props.medicineEnd}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-3 justify-content-center">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row ps-4">
              <div className="col-12 col-md-12 col-lg-12">
                <div className={styles.docGraphImg}>{props.reportImage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorStories;
